import React from "react"

const AddToHomeScreenImage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 262 173"
  >
    <defs>
      <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#0F0F0F" />
        <stop offset="100%" stopColor="#0C6DDE" />
      </linearGradient>
      <linearGradient id="b" x1="50%" x2="50%" y1="0%" y2="73.16%">
        <stop offset="0%" />
        <stop offset="100%" stopColor="#444" />
      </linearGradient>
      <linearGradient id="c" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" />
        <stop offset="100%" stopColor="#E984D5" />
      </linearGradient>
      <path
        id="d"
        d="M36.04 28.04c0 4.4-3.6 8-8 8H8c-4.4 0-8-3.6-8-8V8c0-4.4 3.6-8 8-8h20.04c4.4 0 8 3.6 8 8v20.04z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#a)"
        stroke="#000"
        d="M260.5 0v167c0 2.75-2.25 5-5 5H5.5c-2.75 0-5-2.25-5-5V0"
        transform="translate(1)"
      />
      <path stroke="#231F20" d="M1 96h5M257 96h5" />
      <path
        fill="url(#b)"
        stroke="#000"
        d="M256 0v164c0 2.2-1.8 4-4 4H8c-2.199 0-4-1.8-4-4V0"
        transform="translate(1)"
      />
      <path
        fill="#000"
        d="M157.487 165h-51.974a1.506 1.506 0 0 1-1.513-1.5c0-.828.677-1.5 1.513-1.5h51.974c.836 0 1.513.672 1.513 1.5 0 .829-.677 1.5-1.513 1.5z"
      />
      <path fill="url(#c)" d="M246 0v158H14V0" transform="translate(1)" />
      <path
        fill="#1D0426"
        d="M24.473.462c2.42 1.002 4.924 2.038 9.855 2.038 4.932 0 7.436-1.036 9.857-2.038.379-.157.754-.312 1.136-.462h-2.655c-2.076.819-4.34 1.5-8.338 1.5-3.997 0-6.26-.681-8.336-1.5h-2.655c.38.15.758.305 1.136.462M63.133.462C65.555 1.464 68.059 2.5 72.99 2.5s7.436-1.036 9.857-2.038c.378-.157.755-.312 1.136-.462h-2.655c-2.076.819-4.34 1.5-8.338 1.5-4 0-6.262-.681-8.34-1.5h-2.654c.38.15.757.305 1.136.462M101.795.462c2.422 1.002 4.925 2.038 9.857 2.038 4.933 0 7.436-1.036 9.858-2.038.379-.157.755-.312 1.136-.462h-2.655c-2.076.819-4.34 1.5-8.34 1.5-3.998 0-6.261-.681-8.337-1.5h-2.655c.38.15.758.305 1.136.462M236.003 0c-2.076.82-4.341 1.5-8.341 1.5-4 0-6.264-.68-8.341-1.5h-2.656c.382.15.758.305 1.137.462 2.422 1.002 4.927 2.038 9.86 2.038 4.934 0 7.439-1.036 9.86-2.038.379-.157.755-.312 1.137-.462h-2.656zM179.13.462c2.422 1.002 4.926 2.038 9.858 2.038 4.934 0 7.438-1.036 9.86-2.038.379-.157.755-.312 1.136-.462h-2.655c-2.077.819-4.342 1.5-8.341 1.5-4 0-6.262-.681-8.338-1.5h-2.657c.382.15.758.305 1.137.462M140.46.462c2.422 1.002 4.926 2.038 9.859 2.038s7.437-1.036 9.859-2.038c.379-.157.755-.312 1.136-.462h-2.655c-2.077.819-4.341 1.5-8.34 1.5-3.999 0-6.263-.681-8.339-1.5h-2.657c.382.15.758.305 1.137.462"
      />
      <path
        fill="#310641"
        d="M227.662 5.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038C99.357 2.453 97.055 1.5 92.32 1.5c-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038C60.694 2.453 58.391 1.5 53.658 1.5s-7.036.953-9.474 1.962C41.764 4.464 39.26 5.5 34.328 5.5c-4.932 0-7.435-1.036-9.855-2.038C22.035 2.453 19.733 1.5 15 1.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962C46.223 1.536 48.726.5 53.658.5c4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962C84.886 1.536 87.39.5 92.32.5c4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962C123.55 1.536 126.052.5 130.984.5c4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962C162.217 1.536 164.721.5 169.655.5c4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962C200.887 1.536 203.391.5 208.325.5c4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962C239.561 1.536 242.066.5 247 .5v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#47075F"
        d="M227.662 8.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038C99.357 5.453 97.055 4.5 92.32 4.5c-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038C60.694 5.453 58.391 4.5 53.658 4.5s-7.036.953-9.474 1.962C41.764 7.464 39.26 8.5 34.328 8.5c-4.932 0-7.435-1.036-9.855-2.038C22.035 5.453 19.733 4.5 15 4.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962C46.223 4.536 48.726 3.5 53.658 3.5c4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962C84.886 4.536 87.39 3.5 92.32 3.5c4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962C239.561 4.536 242.066 3.5 247 3.5v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#5F0581"
        d="M227.662 11.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038C99.357 8.453 97.055 7.5 92.32 7.5c-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038C60.694 8.453 58.391 7.5 53.658 7.5s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 8.453 19.733 7.5 15 7.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962C46.223 7.536 48.726 6.5 53.658 6.5c4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962C84.886 7.536 87.39 6.5 92.32 6.5c4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962C239.561 7.536 242.066 6.5 247 6.5v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#8000B0"
        d="M227.662 14.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 11.453 19.733 10.5 15 10.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962C84.886 10.536 87.39 9.5 92.32 9.5c4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 17.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 14.453 19.733 13.5 15 13.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 20.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 17.453 19.733 16.5 15 16.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 23.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 20.453 19.733 19.5 15 19.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 26.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 23.453 19.733 22.5 15 22.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 29.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 26.453 19.733 25.5 15 25.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 32.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 29.453 19.733 28.5 15 28.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 35.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 32.453 19.733 31.5 15 31.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 38.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 35.453 19.733 34.5 15 34.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 41.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 38.453 19.733 37.5 15 37.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 44.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 41.453 19.733 40.5 15 40.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 47.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 44.453 19.733 43.5 15 43.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 50.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 47.453 19.733 46.5 15 46.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 53.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 50.453 19.733 49.5 15 49.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 56.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 53.453 19.733 52.5 15 52.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 59.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 56.453 19.733 55.5 15 55.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 62.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 59.453 19.733 58.5 15 58.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 65.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 62.453 19.733 61.5 15 61.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 68.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 65.453 19.733 64.5 15 64.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 71.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 68.453 19.733 67.5 15 67.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 74.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 71.453 19.733 70.5 15 70.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 77.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 74.453 19.733 73.5 15 73.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 80.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 77.453 19.733 76.5 15 76.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 83.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 80.453 19.733 79.5 15 79.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 86.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 83.453 19.733 82.5 15 82.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 89.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 86.453 19.733 85.5 15 85.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 92.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 89.453 19.733 88.5 15 88.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 95.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 92.453 19.733 91.5 15 91.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 98.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 95.453 19.733 94.5 15 94.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 101.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038C22.035 98.453 19.733 97.5 15 97.5v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 104.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 107.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 110.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 113.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 116.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 119.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 122.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 125.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 128.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 131.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 134.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 137.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 140.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 143.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 146.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 149.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 152.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 155.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M227.662 158.5c-4.934 0-7.438-1.036-9.86-2.038-2.439-1.009-4.743-1.962-9.478-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.86 2.038-4.932 0-7.436-1.036-9.858-2.038-2.439-1.009-4.74-1.962-9.476-1.962-4.734 0-7.038.953-9.476 1.962-2.422 1.002-4.926 2.038-9.859 2.038s-7.437-1.036-9.859-2.038c-2.439-1.009-4.74-1.962-9.476-1.962-4.733 0-7.036.953-9.474 1.962-2.422 1.002-4.925 2.038-9.858 2.038-4.932 0-7.435-1.036-9.857-2.038-2.438-1.009-4.74-1.962-9.475-1.962-4.732 0-7.035.953-9.472 1.962-2.42 1.002-4.925 2.038-9.857 2.038-4.933 0-7.436-1.036-9.858-2.038-2.439-1.009-4.742-1.962-9.475-1.962s-7.036.953-9.474 1.962c-2.42 1.002-4.924 2.038-9.856 2.038-4.932 0-7.435-1.036-9.855-2.038-2.438-1.009-4.74-1.962-9.473-1.962v-1c4.932 0 7.434 1.036 9.855 2.038 2.438 1.01 4.741 1.962 9.473 1.962 4.733 0 7.036-.953 9.474-1.962 2.421-1.002 4.924-2.038 9.856-2.038 4.933 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962s7.036-.953 9.474-1.962c2.421-1.002 4.924-2.038 9.855-2.038 4.933 0 7.436 1.036 9.858 2.038 2.437 1.01 4.741 1.962 9.474 1.962 4.734 0 7.037-.953 9.475-1.962 2.422-1.002 4.925-2.038 9.857-2.038 4.933 0 7.437 1.036 9.86 2.038 2.437 1.01 4.741 1.962 9.475 1.962 4.735 0 7.037-.953 9.476-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.932 0 7.436 1.036 9.858 2.038 2.438 1.01 4.741 1.962 9.475 1.962 4.735 0 7.038-.953 9.477-1.962 2.422-1.002 4.926-2.038 9.86-2.038 4.933 0 7.438 1.036 9.86 2.038 2.438 1.01 4.742 1.962 9.477 1.962s7.04-.953 9.478-1.962c2.421-1.002 4.926-2.038 9.86-2.038v1c-4.735 0-7.039.953-9.478 1.962-2.422 1.002-4.926 2.038-9.86 2.038"
      />
      <path
        fill="#A60CDE"
        d="M169.654 156.5c-4.046 0-6.456.697-8.528 1.5h3.375c1.381-.305 3.02-.5 5.153-.5 2.134 0 3.772.195 5.153.5h3.375c-2.072-.803-4.484-1.5-8.528-1.5M130.984 156.5c-4.044 0-6.455.697-8.527 1.5h3.375c1.381-.305 3.018-.5 5.152-.5 2.134 0 3.772.195 5.153.5h3.375c-2.072-.803-4.484-1.5-8.528-1.5M241.846 158c1.382-.305 3.02-.5 5.154-.5v-1c-4.046 0-6.457.697-8.53 1.5h3.376zM208.324 156.5c-4.046 0-6.456.697-8.528 1.5h3.375c1.381-.305 3.02-.5 5.153-.5 2.135 0 3.772.195 5.154.5h3.376c-2.073-.803-4.484-1.5-8.53-1.5M15 157.5c2.134 0 3.771.195 5.151.5h3.374c-2.071-.803-4.481-1.5-8.525-1.5v1zM92.32 156.5c-4.044 0-6.454.697-8.525 1.5h3.374c1.38-.305 3.017-.5 5.151-.5s3.771.195 5.153.5h3.374c-2.072-.803-4.483-1.5-8.527-1.5M53.658 156.5c-4.044 0-6.454.697-8.526 1.5h3.374c1.381-.305 3.018-.5 5.152-.5 2.134 0 3.771.195 5.152.5h3.375c-2.072-.803-4.483-1.5-8.527-1.5"
      />
      <path
        fill="#FCCBCB"
        fillOpacity=".5"
        d="M68.54 60.85c0 4.4-3.6 8-8 8H40.5c-4.4 0-8-3.6-8-8V40.809c0-4.4 3.6-8 8-8h20.04c4.4 0 8 3.6 8 8v20.04zM122.527 60.85c0 4.4-3.6 8-8 8h-20.04c-4.4 0-8-3.6-8-8V40.81c0-4.4 3.6-8 8-8l20.04-.001c4.4 0 8 3.6 8 8v20.04zM176.513 60.85c0 4.4-3.6 8-8 8h-20.04c-4.4 0-8-3.6-8-8V40.809c0-4.4 3.6-8 8-8h20.04c4.4 0 8 3.6 8 8v20.04zM230.5 60.85c0 4.4-3.6 8-8 8h-20.04c-4.4 0-8-3.6-8-8V40.809c0-4.4 3.6-8 8-8h20.04c4.4 0 8 3.6 8 8v20.04zM68.54 124.477c0 4.4-3.6 8-8 8H40.5c-4.4 0-8-3.6-8-8v-20.04c0-4.4 3.6-8 8-8h20.04c4.4 0 8 3.6 8 8v20.04zM122.527 124.477c0 4.4-3.6 8-8 8h-20.04c-4.4 0-8-3.6-8-8v-20.04c0-4.4 3.6-8 8-8h20.04c4.4 0 8 3.6 8 8v20.04z"
      />
      <g transform="translate(139 96)">
        <mask id="e" fill="#fff">
          <use xlinkHref="#d" />
        </mask>
        <use fill="#000" xlinkHref="#d" />
        <g mask="url(#e)">
          <path
            fill="#FFE222"
            stroke="#CCC"
            d="M8.5 3.5h17.406a2.5 2.5 0 0 1 2.5 2.5v23.378a2.5 2.5 0 0 1-2.5 2.5H8.5V3.5z"
          />
          <path
            fill="#000"
            fillRule="nonzero"
            d="M19.908 12.78c.355.161.719.373 1.09.636.372.263.707.6 1.003 1.013.296.413.541.912.735 1.496.194.584.291 1.279.291 2.083 0 .987-.15 1.815-.452 2.485-.302.67-.693 1.225-1.172 1.665a5.22 5.22 0 0 1-1.608 1.013 9.743 9.743 0 0 1-1.77.523c-.587.113-1.142.177-1.665.193-.523.016-.946.024-1.269.024H13.88V12.09h1.212c.345 0 .714.003 1.107.008.394.005.8.03 1.22.072.42.043.841.113 1.261.21.42.096.83.23 1.229.402zm-3.588 8.67c.765-.053 1.417-.158 1.955-.313.54-.156.98-.373 1.326-.652.344-.279.595-.622.751-1.03.156-.407.235-.89.235-1.447 0-.654-.087-1.196-.259-1.625a2.35 2.35 0 0 0-.792-1.037c-.356-.263-.8-.453-1.333-.571a11.11 11.11 0 0 0-1.883-.225v6.9z"
          />
        </g>
      </g>
      <path stroke="#231F20" d="M247 0v158H15V0" />
    </g>
  </svg>
)

export default AddToHomeScreenImage
